function splitLeft(self, sep, maxSplit, limit) {

  if (typeof maxSplit === 'undefined') {
    var maxSplit = -1;
  }

  var splitResult = self.split(sep);
  var splitPart1 = splitResult.slice(0, maxSplit);
  var splitPart2 = splitResult.slice(maxSplit);

  if (splitPart2.length === 0) {
    splitResult = splitPart1;
  } else {
    splitResult = splitPart1.concat(splitPart2.join(sep));
  }

  if (typeof limit === 'undefined') {
    return splitResult;
  } else if (limit < 0) {
    return splitResult.slice(limit);
  } else {
    return splitResult.slice(0, limit);
  }

}

module.exports = splitLeft;
