const Lang = {

    init() {

        const getQueryLang = () => {
            const url = window.location.href;
            const name = 'lang';
            const regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)");
            const results = regex.exec(url);
            if (!results) {
                return null;
            }
            if (!results[2]) {
                return '';
            }
            return decodeURIComponent(results[2].replace(/\+/g, " "));
        };

        const queryLang = getQueryLang();
        const localeLang = this.getLocale();

        console.log('init lang', {queryLang, localeLang})

        if (queryLang) {
            this.setLocale(queryLang);
        } else if (localeLang) {
            this.setLocale(localeLang);
        } else {
            this.setLocale('en');
        }

        const l = queryLang || localeLang || 'en';
        console.log({ l })

        return l;
    },

    setLocale(locale) {
        const lang = locale || 'en';
        window.localStorage.setItem('lang', lang);
        window.lang = lang;

    },

    getLocale() {

        return window.localStorage.getItem('lang');

    },

};

Lang.init();

// Make public API to change lang
window.i18n = Lang;
module.exports =  Lang;