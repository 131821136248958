const packageInfo = require('../../package');
const merge = require('deepmerge');

require('./lang');

//sharedComponents is a shorcut to the components folder
const componentsConfig = require('sharedComponents/js/config');

const langEs = require('./i18n/es');
const langEn = require('./i18n/en');

const userLang = (window.localStorage.getItem('lang') || 'en').toLowerCase();
const lang = userLang === 'es' ? langEs : langEn;

const internalDebug = {
    VERSION: packageInfo.version,
    DEBUG: packageInfo._debug
};

const mergeConfig = merge.all([componentsConfig, lang.translation, internalDebug]);

window.config = mergeConfig;

module.exports = mergeConfig;
