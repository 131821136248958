module.exports={
  "_debug": true,
  "version": "2.0.0",
  "name": "barsmarts-cms",
  "description": "BarSmarts CMS",
  "keywords": [
    "educational",
    "bartender",
    "barsmarts"
  ],
  "private": true,
  "author": "MakeMePulse (https://www.makemepulse.com/)",
  "dependencies": {
    "async": "^1.5.2",
    "autosize": "^3.0.15",
    "babel-preset-es2015": "^6.5.0",
    "babelify": "^7.2.0",
    "browserify": "^13.0.0",
    "deepmerge": "^2.1.1",
    "foundation-sites": "6.0.x",
    "gulp": "^3.9.1",
    "gulp-autoprefixer": "^3.1.0",
    "gulp-clean-css": "^2.0.7",
    "gulp-empty": "^0.1.1",
    "gulp-livereload": "^3.8.1",
    "gulp-rename": "<=1.2.2",
    "gulp-sass": "^5.1.0",
    "gulp-streamify": "^1.0.2",
    "gulp-tap": "^0.1.3",
    "gulp-uglify": "^1.5.3",
    "gulp-util": "^3.0.7",
    "handlebars": "^4.0.5",
    "i18next": "^11.3.2",
    "include-all": "~0.1.3",
    "moment": "^2.29.1",
    "motion-ui": "^1.2.2",
    "node-sass": "^7.0.1",
    "pathmodify": "^0.5.0",
    "rc": "~0.5.0",
    "request": "^2.69.0",
    "require-dir": "^0.3.0",
    "run-sequence": "^1.1.5",
    "sails": "~0.11.0",
    "sails-disk": "~0.10.0",
    "sails-hook-autoreload": "^0.13.1",
    "sha1": "^1.1.1",
    "stampit": "^2.1.1",
    "string": "^3.3.1",
    "stringify": "^5.0.0",
    "underscore": "^1.8.3",
    "uuid": "^2.0.1",
    "validator": "4.x.x",
    "vinyl-source-stream": "^1.1.0"
  },
  "main": "app.js",
  "scripts": {
    "start": "node app.js",
    "start:localapi": "PORT=1888 node app.js",
    "dev": "concurrently \"npm:start\" \"npm:watch\"",
    "dev:localapi": "concurrently \"npm:start:localapi\" \"npm:watch\"",
    "build": "gulp build",
    "watch": "gulp watch"
  },
  "overrides": {
    "graceful-fs": "^4.2.9"
  },
  "devDependencies": {
    "concurrently": "^7.1.0"
  }
}