

const utils = require('./utils');
const src = require('../templates/inputpasswd/main.html');

const lang = (window.localStorage.getItem('lang') || 'en').toLowerCase();
const template = _.template(src);

const i18nInputPassword = (_lang) => {

    const langs = {
        en: {
            show: 'Show',
            hide: 'Hide'
        },
        es: {
            show: 'Mostrar',
            hide: 'Ocultar'
        }
    };

    if (langs[_lang] !== undefined) {
        return langs[_lang];
    }

    return langs.en;
};

const methods = {

    /**
     * Get or change the type of the instance.
     *
     * @param  {string} type - The new type.
     * @return {String|jQuery} - Return the current type if the parameter `type`
     * is not provided. Otherwise, it returns the same jQuery object.
     */
    type: function (type) {

        const conf = this.data('inputPasswd-config');

        if (!conf) return this;

        const $container = this.parent('.inputpasswd');

        const $msg = $container.find('.inputpasswd__msg');

        // Get the type.
        if (type === undefined) {
            const currentType = this.data('inputPasswd-type');
            return currentType;
        }

        // Change the type.
        else if (typeof type === 'string') {

            if (type === 'password') {
                this.data('inputPasswd-type', 'password');
                $container.removeClass('inputpasswd_text');
                $container.removeClass('active');

                if (!utils.browser.isMobile) {
                    $container.find('input[type=password]').trigger('focus');
                }
            } else {
                this.data('inputPasswd-type', 'text');
                $container.addClass('inputpasswd_text');
                $container.addClass('active');

                if (!$container.find('input[type=text]').length) {
                    $container.append('<input type="text" autocomplete="off">');
                }
                const currentValue = $container.find('input[type=password]').val();
                $container.find('input[type=text]').val(currentValue);

                if (!utils.browser.isMobile) {
                    $container.find('input[type=text]').trigger('focus');
                }
            }
        }

        // Type is not known.
        else {
            throw new Error(`inputPasswd does not accept "${type}" parameter in method type.`);
        }
    }
};


/**
 * Plugin API.
 *
 * @param  {Object|String} [conf] - The new instance configuration or the method
 * of the plugin we want to use.
 * @param  {String} [conf.type] - The initial type of the instance.
 *
 * @return {jQuery}
 **/
jQuery.fn.inputPasswd = function (conf) {

    // Call a method.
    if (typeof conf === 'string') {
        if (methods[conf]) {
            let args = Array.prototype.slice.call(arguments, 1);
            return methods[conf].apply(this, args);
        } else {
            throw new Error('no valid inputPasswd method "'+ conf +'"');
        }
    }

    // Initialize the plugin instance.
    if (this.data('inputPasswd')) {
        return this;
    }

    this.data('inputPasswd', true);

    // Parse config.
    conf = $.extend({}, {
        type: 'password'  // The type of the instance: password | text
    }, conf);

    this.each(function () {
        let $el = $(this);

        $el.data('inputPasswd-config', conf);
        $el.data('inputPasswd-type', 'password');

        let $container = $(template({
            type: conf.type,
            text: i18nInputPassword(lang).show
        }));
        $el.after($container);
        $container.append($el);

        // When the parent form is about to be submited, remove the text field
        // to prevent confusing the browser.
        $el.parents('form:first').
            on('keydown', 'input', function (e) {
                if (e.which === 13) {
                    methods.type.call($el, 'password');
                    $container.find('input[type=text]').remove();
                }
            }).
            on('click', 'button[type=submit], input[type=submit]', function (e) {
                methods.type.call($el, 'password');
                $container.find('input[type=text]').remove();
            });

        // Change the type of the input.
        $container.find('.inputpasswd__msg').on('click', function (e) {
            const type = $el.data('inputPasswd-type');

            if (type === 'password') {
                methods.type.call($el, 'text');
            } else {
                methods.type.call($el, 'password');
            }
        });

        // Share the input text between the inputs.
        $container.on('change blur keyup input paste', 'input[type=text]', function (e) {
            let $input = $(this);
            const val = $input.val();

            $container.find('input').not($input).val(val).trigger('change');
        });
    });

    return this;
};
