//
// TRADUCCUÓN EN ESPAÑOL
//

const translation = {

    SETTINGS: {

        DROPDOWN: {
            SELECT: 'Selección'
        },

        DATEFORMAT: {
            TEXT_MIN: 'Min',
            TEXT_HOURS: 'Horas'
        },

        PROGRAMS: {
            BARSTARTS: {
                DESCRIPTION: 'Bebidas espirituosas y vinos para prinicipantes'
            },
            BARSMARTS: {
                DESCRIPTION: 'Curso integral de bebidas espirituosas para Bartenders'
            },
            BARPREP: {
                DESCRIPTION: ""
            },
            ADVANCED: {
                NAME: 'Avanzado',
                DESCRIPTION: "Aprenda de los expertos en la industria. Nuestro equipo de expertos, tienen años de experiencia y habilidades de enseñanza para proporcionar un curso de primera categoría de principio a fin."
            }
        },

        USA_COUNTRY: {
            name: 'Estados Unidos'
        },

        NOUSA_STATE: {
            name: 'Otro'
        },

        // vFileUploader component messages.
        FILEUPLOADER: {

            DROP_FILE: 'Arrastre y suelte un archivo o búsquelo',
            DROP_IMAGE: 'Arrastre y suelte una imagen o búsquela',

            UPLOAD_FILE: 'Click para subir un nuevo archivo',
            UPLOAD_IMAGE: 'Click para subir una nueva imagen',

            ERROR_SERVER: 'Error interno, intente nuevamente',

            // File is too large.
            ERROR_SIZE_EXCEEDED: {
                TITLE: 'Archivo Inválido',
                DESCRIPTION: 'El archivo excede el tamaño máximo. Intente con otro archivo.'
            },

            // File format is invalid.
            ERROR_FORMAT: {
                TITLE: 'Archivo Inválido',
                DESCRIPTION: 'El formato del archivo es inválido. Archivos aceptados: {{=formats}}.'
            }
        }
    },

    CMS: {

        // General application configurations.
        "APP": {

            // General messages.
            "MESSAGES": {

                // When the user tries to enter an unauthorized site or try to
                // execute a forbidden action.
                "FORBIDDEN": {
                    "TITLE": "No Autorizado",
                    "DESCRIPTION": "Su cuenta no posee los permisos para realizar esa acción.",
                    "BUTTON": "Continuar"
                }
            }
        },

        // Configurations in the pages of the menu "Programs".
        "PROGRAMS": {

            // General messages.
            MESSAGES: {

                // Error HTTP GET.
                ERROR_GET: {
                    TITLE: 'Error',
                    DESCRIPTION: 'Se ha produdico un error al obtener la inforamción.',
                    BUTTON: 'Regresar a los Programas'  // Back to programs page.
                },

                // Error HTTP POST/PUT/PATCH/DELETE.
                ERROR_UPDATE: {
                    TITLE: 'Error',
                    DESCRIPTION: 'Se ha producido un error al actualizar la información.',
                    BUTTON: 'Recargar Página'  // Will refresh the page.
                }
            },

            // Creating/editing sections.
            SECTIONS: {

                MESSAGES: {
                    DELETE: {
                        TITLE: 'Eliminar Módulo',
                        DESCRIPTION: 'Está seguro de querer eliminar este módulo? Todos los capítulos se eliminarán también. Tenga en cuenta que esta acción es permanente.',
                        ACCEPT: 'Eliminar',
                        CANCEL: 'Cancelar'
                    }
                },

                // Default values to create a new module.
                DEFAULTS: {
                    TITLE: 'Módulo sin título'
                }

            },

            // Creating/editing chapters.
            "CHAPTERS": {

                MESSAGES: {

                    // Message to prevent the user from leaving the page when s/he has
                    // unsaved changes.
                    UNSAVED_CHANGES: "Este capítulo tiene cambios sin guardar. Desea salir de la página sin guardar los cambios?",

                    // Delete chapter.
                    DELETE: {
                        TITLE: 'Eliminar Capítulo',
                        DESCRIPTION: 'Está seguro de querer eliminar este capítulo? Tenga en cuenta que esta acción es permanente.',
                        ACCEPT: 'Eliminar',
                        CANCEL: 'Cancelar'
                    },

                    // A warning message showd once per session indicating that any fragment edition/removal
                    // will cause all its related notes and highlights being deleted.
                    FRAGMENT_EFFECTS_WARNING: {
                        TITLE: 'Advertencia',
                        DESCRIPTION: 'Cuando un párrafo, subtítulo o nota es editado o eliminado, Todas las notas y resaltados generados en el Centro Educativo por los usuarios se borrarán.',
                        BUTTON: 'ACEPTAR'
                    }
                },

                // Default values to create a new chapter.
                DEFAULTS: {
                    TITLE: 'Capítulo sin título',
                    DESCRIPTION: 'Capítulo sin descripción...'
                }
            }
        },

        "MANAGERS": {
          GRADUATIONS: "Grados",
          INCOMPLETE: "Incompleto",
          USERS: "Usuarios",
          VIEW: "Ver",

          ADD_STAFF: "Agregar personal",
          ADD_EMAILS: "Agregar correos electrónicos separados por coma",
          ADD_STAFF_ERROR: "Ingrese una dirección de correo válida",
          INVITE: "Invitar",

          DOWNLOAD_CERTIFICATE: "Descargar Certificado",
          TEST: "Prueba",
          RESULT: "Resultado",
          SCORE: "Puntaje",
          ATTEMPTS: "Intentos",
          MODULE: "Módulo",

          DAY_REMAINING: "Día Restante",
          DAYS_REMAINING: "Días Restantes"
        }

    }

};

module.exports = { translation };
