const INPUTVAL_EVENT_PREFIX = 'util-val';
const INPUTVAL_DATA_PREFIX = 'util-val';
const INPUTVAL_CLASS_ERROR = 'field-error';
const INPUTVAL_CLASS_PREFIX = 'field-msg';

/**
 * Make a list of inputs, textareas and selects validate in realtime.
 * Requires `underscore`.
 *
 * @param {Object|Array} conf - The configuration to validate or a shortcut
 * to the parameter validators.
 *
 * @param {Array} conf.validators - This is an collection of objects with the
 * validator function and the name of the class sufix of the message which
 * will display the corresponding error message. The input will evaluate every
 * function secuentialy every time it changes. If one of them fails, the
 * respective message related to the validator should show, the others should
 * hide and the validations stop.
 *
 * If it receives a selector with more than one element, every function validator
 * will receive the same number of values corresponding to each as parameters.
 *
 * @param {jQuery} [conf.$msgsContainer] - Where the messages are located. By default
 * will look for the input siblings. Default: null, and will try to find them by
 * the looking for the children of the nearest <label> parent of the <input>.
 *
 * @param {Boolean} [conf.started] - If the validators will trigger once they are
 * instantiated. Default: false.
 *
 * @example
 *
 * An example with one <input> and two validators:
 *
 * ```html
 * <label for="input-id">
 *   <span>What is this input for?</span>
 *   <input id="input-id" type="text">
 *   <div class="field-msg field-msg-basic">The "basic" error message.</div>
 *   <div class="field-msg field-msg-crazy">The "crazy" error message.</div>
 * </label>
 * ```
 *
 * ```js
 * $('#input-id').inputValidator({
 *   started: true,
 *   validators: [
 *     {
 *       name: 'basic',
 *       fn: v => v.length > 10
 *     },
 *     {
 *       name: 'crazy',
 *       fn: v => v.length < 100
 *     }
 *   ]
 * });
 * ```
 *
 * @return {jQuery}
 */
jQuery.fn.inputValidator = function (conf) {
    const _this = this;

    if ($.isArray(conf)) {
        conf = {
            validators: conf
        };
    }

    // TODO: Methods to try to validate and see if it is valid without validate.

    conf = $.extend({}, {
        started: false,
        $msgsContainer: null,
        validators: [],
        extraValues: []
    }, conf);

    if (!$.isArray(conf.validators) || !conf.validators.length) {
        throw new Error('jQuery inputValidator needs validators to instantiate.');
    }

    if (!this.length) {
        return this;
    }

    conf.$msgsContainer = conf.$msgsContainer ? $(conf.$msgsContainer) : null;

    const isField = (this[0].nodeName.toLowerCase() === 'input' &&
        this.attr('type') !== 'checkbox' && this.attr('type') !== 'radio') ||
        this[0].nodeName.toLowerCase() === 'textarea';

    let eventsSet = false;

    const setEvents = () => {
        if (eventsSet) return false;
        eventsSet = true;

        _this.on('keyup change', function () {
            const $input = $(this);
            const $container = conf.$msgsContainer ?
                               conf.$msgsContainer :
                               $input.parents('label');

            let values = [];
            let fn, name;

            // Get the value of each input in the jQuery selector and store it
            // in the array of values.
            _this.each((index, input) => {
                values.push(
                    $(input).attr('type') === 'checkbox' ?
                    input.checked :
                    $(input).val()
                );
            });

            _(conf.extraValues).each(function (extra) {
                values.push(typeof extra === 'function' ? extra() : $(extra).val());
            });

            _(conf.validators).every(function (validator) {
                fn = validator.fn;
                name = validator.name;

                $container.find(`.${INPUTVAL_CLASS_PREFIX}`).slideUp(0);

                if (fn.apply(null, values)) {
                    $container.removeClass(INPUTVAL_CLASS_ERROR);

                    _this.data(`${INPUTVAL_DATA_PREFIX}-isvalid`, true);

                    return true;
                } else {
                    $container.addClass(INPUTVAL_CLASS_ERROR);
                    $container.find(`.${INPUTVAL_CLASS_PREFIX}-${name}`).slideDown(0);

                    _this.data(`${INPUTVAL_DATA_PREFIX}-isvalid`, false);

                    // Trigger error event when it occurs.
                    _this.trigger(`${INPUTVAL_EVENT_PREFIX}-error`, values);

                    return false;
                }
            });
        });
    };

    const initialize = () => {
        setEvents();
        this.first().trigger('change');
    };

    const verifying = () => {
        if (isField && this.val().length > 0) {
            initialize();
        }
    };

    this.on(`${INPUTVAL_EVENT_PREFIX}-validate`, () => {
        setEvents();
        _this.first().trigger('change');
    });

    // If the input is initially empty, only validate it at instantiation time when
    // the config property "started" is true. Or validate it when it has initial content.
    if (conf.started || (isField && this.val().length > 0)) {
        initialize();
    } else {
        this.each((index, item) => {
            $(item).data(`${INPUTVAL_DATA_PREFIX}-started`, false);
        });

        this.one('change', function () {
            $(this).data(`${INPUTVAL_DATA_PREFIX}-started`, true);

            if (_(_this).every(i => $(i).data(`${INPUTVAL_DATA_PREFIX}-started`))) {
                initialize();
            }
        });
    }

    // When the browser autofills inputs, there is no proper way to detect those
    // changes, so we need some trick to do this and validate the input.
    $(window).on('load', e => {
        setTimeout(verifying, 1000);
        setTimeout(verifying, 2000);
    });

    return this;
};
