const validator = require('validator');
const S = require('string');


/**
 * Normal text validator.
 * @param  {String} v
 * @param  {Object} conf
 * @return {Boolean}
 */
validator.extend('isNormalText', function (v, conf) {

    conf = _({}).extend({
        min: 0,
        max: 528
    }, conf);

    return /^[-.,/#&+\'+\"+À-ÿ+\w\s]*$/.test(v) && v.length >= conf.min && v.length <= conf.max;
});


/**
 * Normal long text validator.
 * @param  {String} v
 * @param  {Object} conf
 * @return {Boolean}
 */
validator.extend('isLongText', function (v, conf) {

    conf = _({}).extend({
        min: 0,
        max: 2048
    }, conf);

    return /^[-_.,:;?!/#&+\'+\"+À-ÿ+()\w\s]*$/.test(v) && v.length >= conf.min && v.length <= conf.max;
});


/**
 * Normal password.
 * @param  {String} v
 * @param  {Object} conf
 * @return {Boolean}
 */
validator.extend('isNormalPassword', function (v, conf) {

    conf = _({}).extend({
        min: 8,
        max: 64
    }, conf);

    return /^[-.,/#&+\'+\"+À-ÿ+\w]*$/.test(v) && v.length >= conf.min && v.length <= conf.max;
});


/**
 * A valid USA ZIP code.
 * @param  {String} v
 * @param  {Object} conf
 * @return {Boolean}
 */
validator.extend('isUSAZIP', function (v) {
    return /^[A-Z0-9-\.\s]{4,15}$/i.test(v);
});
