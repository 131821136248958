//
// ENGLISH TRANSLATIONS
//

const translation = {

    SETTINGS: {

        DROPDOWN: {
            SELECT: 'Select'
        },

        DATEFORMAT: {
            TEXT_MIN: 'Min',
            TEXT_HOURS: 'Hours'
        },

        PROGRAMS: {
            BARSTARTS: {
                DESCRIPTION: 'Spirits and wine knowledge for beginners'
            },
            BARSMARTS: {
                DESCRIPTION: 'Comprehensive Bartenders spirits training'
            },
            BARPREP: {
                DESCRIPTION: ""
            },
            ADVANCED: {
                NAME: 'Advanced',
                DESCRIPTION: "Learn from industry experts. Our team of experts combine years of experience and teaching skills to provide a top notch course from start to beginning"
            }
        },

        USA_COUNTRY: {
            name: 'United States'
        },

        NOUSA_STATE: {
            name: 'Other'
        },

        // vFileUploader component messages.
        FILEUPLOADER: {

            DROP_FILE: 'Drag and drop file or browse',
            DROP_IMAGE: 'Drag and drop image or browse',

            UPLOAD_FILE: 'Click to upload a new file',
            UPLOAD_IMAGE: 'Click to upload a new image',

            ERROR_SERVER: 'Error, please try again',

            // File is too large.
            ERROR_SIZE_EXCEEDED: {
                TITLE: 'Invalid file',
                DESCRIPTION: 'File exceeds the maximum size. Please use another.'
            },

            // File format is invalid.
            ERROR_FORMAT: {
                TITLE: 'Invalid file',
                DESCRIPTION: 'File format is invalid. Accepted file types include: {{=formats}}.'
            }
        }
    },

    CMS: {

        // General application configurations.
        "APP": {

            // General messages.
            "MESSAGES": {

                // When the user tries to enter an unauthorized site or try to
                // execute a forbidden action.
                "FORBIDDEN": {
                    "TITLE": "Forbidden",
                    "DESCRIPTION": "You are not authorized to do this action.",
                    "BUTTON": "Continue"
                }
            }
        },

        // Configurations in the pages of the menu "Programs".
        "PROGRAMS": {

            // General messages.
            MESSAGES: {

                // Error HTTP GET.
                ERROR_GET: {
                    TITLE: 'Error',
                    DESCRIPTION: 'There was an error while getting the information.',
                    BUTTON: 'Return to Programs'  // Back to programs page.
                },

                // Error HTTP POST/PUT/PATCH/DELETE.
                ERROR_UPDATE: {
                    TITLE: 'Error',
                    DESCRIPTION: 'There was an error while updating the information.',
                    BUTTON: 'Refresh Page'  // Will refresh the page.
                }
            },

            // Creating/editing sections.
            SECTIONS: {

                MESSAGES: {
                    DELETE: {
                        TITLE: 'Delete Module',
                        DESCRIPTION: 'Are you sure you want to delete this module? All chapters will be deleted as well. Please note, this action is permanent.',
                        ACCEPT: 'Delete',
                        CANCEL: 'Cancel'
                    }
                },

                // Default values to create a new module.
                DEFAULTS: {
                    TITLE: 'Untitled module'
                }

            },

            // Creating/editing chapters.
            "CHAPTERS": {

                MESSAGES: {

                    // Message to prevent the user from leaving the page when s/he has
                    // unsaved changes.
                    UNSAVED_CHANGES: "This chapter has unsaved changes. Do you want to leave the page and discard your changes?",

                    // Delete chapter.
                    DELETE: {
                        TITLE: 'Delete Chapter',
                        DESCRIPTION: 'Are you sure you want to delete this chapter? Please note, this action is permanent.',
                        ACCEPT: 'Delete',
                        CANCEL: 'Cancel'
                    },

                    // A warning message showd once per session indicating that any fragment edition/removal
                    // will cause all its related notes and highlights being deleted.
                    FRAGMENT_EFFECTS_WARNING: {
                        TITLE: 'Warning',
                        DESCRIPTION: 'When a chapter paragraph, subtitle or quote is edited or deleted, all Education Center notes and highlights related to that fragment of content created by users will be deleted.',
                        BUTTON: 'OK'
                    }
                },

                // Default values to create a new chapter.
                DEFAULTS: {
                    TITLE: 'Untitled chapter',
                    DESCRIPTION: 'Undescribed chapter...'
                }
            }
        },

        "MANAGERS": {
          GRADUATIONS: "Graduations",
          INCOMPLETE: "Incomplete",
          USERS: "Users",
          VIEW: "View",

          ADD_STAFF: "Add Staff",
          ADD_EMAILS: "Add emails separated by comma",
          ADD_STAFF_ERROR: "Enter a valid email address",
          INVITE: "Invite",

          DOWNLOAD_CERTIFICATE: "Download Certificate",
          TEST: "Test",
          RESULT: "Result",
          SCORE: "Score",
          ATTEMPTS: "Attempts",
          MODULE: "Module",
          DAY_REMAINING: "Day Remaining",
          DAYS_REMAINING: "Days Remaining"
        }

    }

};

module.exports = { translation };
