/**
 * This module is used only for the Login Application which is apart from the
 * CMS application. This is the Login entry point.
 */

// Polyfills
require('sharedComponents/js/browserStoragePolyfill');

// Plugins.
require('sharedComponents/js/inputpasswd');
require('sharedComponents/js/input-validator');

const validator = require('validator');
const sha1      = require('sha1');
const S = require('string');

const config = require('../config');
const messages = require('./messages');
const utils = require('sharedComponents/js/utils');
const templateError = _.template(require('./templates/error.html'));

const ajax = app.apiAjax;


/**
 * The server API.
 *
 * @type {Object}
 */
const state = {

  // The API base URL.
  api: config.API.SERVER,

  // If the login form is sendind.
  loginSending: false
};


/**
 * Validate the login form and return a boolean telling it. If any of the form
 * fields are invalid, make validate it and focus it.
 *
 * @return {Boolean}
 */
const validateLoginForm = () => {

  if (!$('#email').data('util-val-isvalid')) {
    $('#email').trigger('change').trigger('focus');
    return false;
  } else if (!$('#password').data('util-val-isvalid')) {
    $('#password').trigger('change').trigger('focus');
    return false;
  }

  return true;
};


/**
 * Redirect to CMS when user is logged.
 */
const redirectToApp = (role) => {
  const vars = window.location.search.
    replace('?', '').
    split('&').
    map(s => s.split('=')).
    map(ar => ({ name: ar[0], value: ar[1] }));

    const redirect = _(vars).findWhere({ name: 'redirect' });

    if (role === 3) {
        window.location.href = '/managers';
    } else {
        if (redirect) {
        window.location.href = S(redirect.value).ensureLeft('/');
        } else {
            window.location.href = '/';
        }
    }

};


$(document).ready(function ($) {

  // Set static links.
  $('#link-forgot-password').attr('href', config.API.EDUCATION_CENTER_LOGIN_FORGOT);
  $('#link-register').attr('href', config.API.EDUCATION_CENTER_REGISTER);

  $('#email').inputValidator({
    validators: [{
      name: 'basic',
      fn: v => validator.isEmail(v)
    }]
  });

  $('#password').inputValidator({
    validators: [{
      name: 'basic',
      fn: v => v.length > 0
    }]
  });

  $('#password').inputPasswd();

  // Log in.
  $("#login-form").on('submit', function (e) {

    e.preventDefault();

    const isValid = validateLoginForm();

    if (!isValid) return;

    if (state.loginSending) return;
    state.loginSending = true;
    $('#login-btn').text('ENTERING');

    const email = $("input.email-field").val();
    const password = $("input.myPass").val();

    ajax.request({
      endpoint: '/login',
      type: 'POST',
      data: { email, password }
    }).
    always(function() {
      state.loginSending = false;
      $('#login-btn').text('ENTER');
    }).
    done(function(result) {

        // Set the user name.
        if (result.success) {
            if(result.data.user.roles.id === 1) {
              window.localStorage.setItem('lang', 'en');
              window.localStorage.setItem('cms_lang', 'en');
              window.lang = 'en';
              document.cookie = `lang=en; path=/`;
              document.cookie = `ui_lang=en; path=/`;
              document.cookie = `cms_lang=en; path=/`;
            }

            if(result.data.user.roles.id > 3) {
                const $errorMessage = $(templateError({ message: messages.login['401'] }));
                $errorMessage.find('a').on('click', e => $errorMessage.remove());
                $('#login-messages').html($errorMessage);

            } else {
                // window.localStorage.setItem("user-name",`${result.data.user.first_name} ${result.data.user.last_name}`);
                // window.localStorage.setItem("user-rol",result.data.user.roles.name);
                // window.localStorage.setItem('role', result.data.user.roles.id+'-'+sha1(result.data.user.roles.id+'-'+result.data.token));
                // window.localStorage.setItem('token', result.data.token);
                utils.cookie.set("user-name",`${result.data.user.first_name} ${result.data.user.last_name}`);
                utils.cookie.set("user-rol",result.data.user.roles.name);
                utils.cookie.set('role', result.data.user.roles.id+'-'+sha1(result.data.user.roles.id+'-'+result.data.token))
                utils.cookie.set('token_auth', result.data.token)
                redirectToApp(result.data.user.roles.id);
            }

        }
    }).
    fail(function(xhr) {

      const message = xhr.status === 401 ? messages.login['401'] : messages.login.all;
      const $errorMessage = $(templateError({ message }));

      $errorMessage.find('a').on('click', e => $errorMessage.remove());

      $('#login-messages').html($errorMessage);
    });

    return false;
  });

  // Verify if the user is already logged in. Then, redirect to the app.
  ajax.request('isAuthenticated').done(function(r) {
      if(r.success && r.data === 'is_logged_in') {
        redirectToApp();
      }
  });

  // Focus email input if device is not mobile.
  if (!utils.browser.isMobile) {
    setTimeout(() => $('#email').trigger('focus'), 0);
  }
});
