/**
 * Configuration constants for BarSmarts client applications.
 */

// Polyfills and plugins.
require("sharedComponents/js/browserStoragePolyfill");

// Get translations by language
const langUser = (window.localStorage.getItem("lang") || "en").toLowerCase();
const langToEC = langUser === "es" ? `?lang=${langUser}` : "";

let URL_API = "https://api.barsmarts.com/v1";
let URL_WEBSITE = "https://www.barsmarts.com";
let URL_EC = "https://www.barsmarts.com";
let URL_CMS = "https://admin.barsmarts.com";
let EC_GA_CODE = false;

const url = String(window.location.href);

// Add lang to website
// NO MORE THIS FORMAT
// URL_WEBSITE += langUser === "es" ? "/es" : "";

const config = {
  // API resources.
  API: {
    // The Server API base URL, where the database is located, which is used
    // as an endpoint in all XHR requests.
    SERVER: URL_API,

    // The Website URLs.
    WEBSITE: URL_WEBSITE,
    WEBSITE_INFO: `${URL_WEBSITE}/company_info.html`,
    WEBSITE_PRIVACY: `${URL_WEBSITE}/privacy.html`,
    WEBSITE_TERMS: `${URL_WEBSITE}/terms.html`,
    WEBSITE_TERMS_ADS: `${URL_WEBSITE}/terms-ads.html`,
    WEBSITE_ADVANCED: `${URL_WEBSITE}/advanced.html`,
    WEBSITE_CONTACT: `${URL_WEBSITE}/contact_us.html`,
    WEBSITE_GLOSSARY: `${URL_WEBSITE}/glossary.html`,
    WEBSITE_FAQ: `${URL_WEBSITE}/faq.html`,
    WEBSITE_TIPS: `${URL_WEBSITE}/tips.html`,

    // The Education Center URLs.
    EDUCATION_CENTER: `${URL_WEBSITE}${langToEC}`,
    EDUCATION_CENTER_LOGIN: `${URL_WEBSITE}/login${langToEC}`,
    EDUCATION_CENTER_LOGIN_FORGOT: `${URL_WEBSITE}/forgot-password${langToEC}`,
    EDUCATION_CENTER_REGISTER: `${URL_WEBSITE}/register${langToEC}`,

    // CMS URLs.
    CMS: URL_CMS,

    // Social media links.
    SOCIAL_FACEBOOK: "https://www.facebook.com/barsmarts",
    SOCIAL_TWITTER: "https://twitter.com/barsmarts",
    SOCIAL_INSTAGRAM: "https://www.instagram.com/barsmarts",
    SOCIAL_TUMBLR: "http://barsmarts.tumblr.com",

    // If there is data stored in the client browser storage and needs to be
    // reseted because the API used there has changed. Just change the number
    // and automatically the applications should fetch and save data with
    // different keys.
    BROWSER_STORAGE: "003",

    // The YouTube API v3.0 key. This is used in components which need to
    // fetch video information. For example: the videolightbox.
    YOUTUBE: "AIzaSyBVvlBuAOKM38JNepuzCT-aaAm7gCdZXJc",
  },

  // General application configurations.
  SETTINGS: {
    // Information about the static programs the platform has defined.
    // Many of the subapplications, views and components of the application
    // are tied to these static programs. Here are some data to reference.
    PROGRAMS: {
      BARSTARTS: {
        ID: "1",
        POS: 1,
        DESCRIPTION: "Spirits and wine knowledge for beginners",
      },
      BARSMARTS: {
        ID: "2",
        POS: 2,
        DESCRIPTION: "Comprehensive Bartenders spirits training",
      },
      BARPREP: {
        ID: "4",
        POS: 3,
        DESCRIPTION: "",
      },

      // Currently, we don't fetch this as a program, but we use this information
      // as one.
      ADVANCED: {
        ID: "9999",
        NAME: "Advanced",
        DESCRIPTION:
          "Learn from industry experts. Our team of experts combine years of experience and teaching skills to provide a top notch course from start to beginning",
      },
    },

    // Information about the course.
    COURSE: {
      TIME: {
        // The time scale the server stores duration values. This is used
        // to convert time durations fetched from the server and use them
        // in the client side.
        SCALE: "milliseconds",

        // How long a course last. This time is in scale of `this.SCALE`.
        DURATION: 5184000000,

        // The half of the total duration.
        DURATION_HALF: 2592000000,
      },

      DATETIME: {
        // The datetime offset the server stores datetime values. This is
        // used to convert datetime values from the server to the user
        // local offset.
        OFFSET: "+00:00",
      },

      // Information about general tests.
      TEST: {
        // The max number attempts for a user to make a test.
        MAX_ATTEMPTS: 2,
        MAX_ANSWERS_PER_QUESTION: 4,
      },
    },

    // Notifications in general.
    NOTIFICATIONS: {
      CONSTANTS: {
        ACTION_REQUEST_TEST_ATTEMPT: "#/request-evaluation-attempts",
        ACTION_REQUEST_EXAM_ATTEMPT: "#/request-evaluation-attempts",
        ACTION_REQUEST_TIME: "#/request-program-time",

        LINK_BARSMARTS_SIGNUP: `#/course/register/2`,
        LINK_ADVANCED: `${URL_WEBSITE}/advanced.html`,
        LINK_CONTACT_US: `${URL_WEBSITE}/contact_us.html`,

        LINK_REPORTS_USERS: `${URL_CMS}/reports/programs`,
        LINK_REPORTS_SALES: `${URL_CMS}/reports/sales`,
      },

      VARIABLES: {
        // Mixed.
        course: "course", // Program name
        userName: "user", // User name
        sectionName: "module", // Section name
        user: "", // User identifier
        section: "", // Section/module identifier
        test: "", // DEPRECATED
        exam: "", // DEPRECATED
        evaluation: "", // Test or exam identifier

        // CMS.
        marketName: "market",
        revenue: "$-1",
        quantity: "-1",
        datetime: "MM/DD/YYYY",
      },
    },

    // Country USA.
    USA_COUNTRY: {
      id: "230",
      name: "United States",
    },

    // When a country is chosen (in any interface) and it is not USA, the State
    // by default is next.
    NOUSA_STATE: {
      id: "52",
      name: "Other",
    },

    // Information about the application layout.
    LAYOUT: {
      // Dimentions breakpoints.
      DIMS: {
        MOBILE: 767,
        MEDIUM: 1000,
      },
    },

    // vFileUploader component messages.
    FILEUPLOADER: {
      DROP_FILE: "Drag and drop file or browse",
      DROP_IMAGE: "Drag and drop image or browse",

      UPLOAD_FILE: "Click to upload a new file",
      UPLOAD_IMAGE: "Click to upload a new image",

      ERROR_SERVER: "Error, please try again",

      // File is too large.
      ERROR_SIZE_EXCEEDED: {
        TITLE: "Invalid file",
        DESCRIPTION: "File exceeds the maximum size. Please use another.",
      },

      // File format is invalid.
      ERROR_FORMAT: {
        TITLE: "Invalid file",
        DESCRIPTION:
          "File format is invalid. Accepted file types include: {{=formats}}.",
      },
    },
  },

  // Configuration for dinamic animations.
  ANIM: {
    // Average animation duration.
    TIME: 250,
  },

  // Education Center configuration constants.
  EC: {
    COMMON: {},

    // Google Analytics.
    GA: {
      CODE: EC_GA_CODE,
    },

    // Notifications component which is currently used in the header.
    NOTIFICATIONS: {
      // How often we fetch notificacions from the server.
      FETCH_INTERVAL: 5 * 60 * 1000, // 5 min

      // When a user reads the notificacions, how long should we wait to
      // update the read in the server.
      UPDATE_DELAY: 1000, // 1 seg
    },

    // Application manager. These are general configurations for the application.
    APP: {
      // Global messages data.
      MESSAGES: {
        // Static message: Remaining days are the half of the whole course duration.
        REMDAYS_HALF: `You're half way through the course time. If you have any questions or comments, please <a href="{{=CONTACT_US}}">contact us</a>.`,

        // Static message: Remaining days are less or equal than 5 days.
        REMDAYS_LOE5DAYS: `You have {{=days}} days remaining to complete the course. Remember you won't have access to take or pass tests once the time runs out.`,

        // Static message: User ran out of time.
        REMDAYS_0DAYS: `You've run out of time! Need more time? Submit a request and our educational specialists will get in touch with you. <a href="{{=REQUEST_TIME}}">Request More Time</a>.`,

        // When the user fails a test in the first attempt.
        TEST_1FAIL: `Remember you have 1 more attempt to pass {{=sectionName}} test. Review the course material and try again.`,

        // When the user fails a test in the second attempt.
        TEST_2FAIL: `Need additional chances to pass {{=sectionName}} test? Submit a request and our education specialists will get in touch with you. <a href="{{=REQUEST_TEST_ATTEMPTS}}">Request additional attempts</a>.`,

        // When a Test is in course.
        TEST_INPROGRESS: `Your {{=name}} test is currently active. <a href="{{=link}}">Click here to continue</a>.`,

        // When an exam is in course.
        EXAM_INPROGRESS: `Your DrinkBuilder Final Exam is currently active. <a href="{{=link}}">Click here to continue</a>.`,

        // User has completed the DrinkBuilder in the BarStarts program.
        EXAM_GRADUATE_BARSTARTS: `Congratulations you are a BarStarts graduate! Next up, take your mixology skills to a professional level. <a href="{{=BARSMARTS_SIGNUP}}">Sign up</a> for BarSmarts now.`,

        // User has completed the DrinkBuilder in the BarSmarts program.
        EXAM_GRADUATE_BARSMARTS: `Congratulations you are a BarSmarts graduate! You are now eligible to attend exclusive BarSmarts Advanced events and receive our highest level of certification. <a href="{{=ADVANCED}}">Check the schedule</a> and sign up for one now.`,

        // User has completed the DrinkBuilder in the BarPREP program.
        EXAM_GRADUATE_BARPREP: `Congratulations you are a BarPREP graduate!`,

        // Fatal error, we have to logout the user.
        FATAL_ERROR: {
          TITLE: "We are sorry!",
          DESCRIPTION:
            "There was an error while processing your information. We will fix it as soon as possible.",
          BUTTON: "Try again",
        },

        // Low error in application.
        LOW_ERROR: {
          TITLE: "We are sorry",
          DESCRIPTION:
            "There was an error while processing your request. We will fix it as soon as possible. Please try again or refresh the page.",
          BUTTON: "OK",
        },

        // User ran out of time in the program.
        RUN_OUT_OF_TIME: {
          TITLE: "Your time has run out!",
          DESCRIPTION: "Submit a request and we will follow up with you.",
          BUTTON: "Request More Time",
        },

        // A request (program time, test attempt, exam attempt) has been
        // sent to the server successfully.
        REQUEST_SENT: {
          TITLE: "Request Sent",
          DESCRIPTION:
            "You will be able to retake this test in 24 hours. An email will be sent to you when this test has been re-opened.",
          BUTTON: "OK",
        },

        // A request to reset time have been sent successfully.
        REQUEST_TIME_RESETED: {
          TITLE: "Time Reset",
          DESCRIPTION:
            "Your time was successfully reset. You now have a fresh 60 days to complete the program.",
          BUTTON: "Continue",
        },

        // When an user has currently an evaluation in progress or active
        // and wants to enter contents and other evaluations.
        CONTENT_BLOCKED_BY_EVAL: {
          TITLE: "Test In Progress",
          DESCRIPTION:
            "While your test is in progress the course material is not available. Please either complete or exit the test to regain access.",
          BUTTON: "OK",
        },

        // Modal to ask an user to switch program when she has more than one.
        SWITCH_PROGRAM: {
          TITLE: "Select the course you want to enter:",
          CHECK: "Remember my choice",
          BUTTON: "Enter",
        },

        // Registration over
        EVENT_REGISTRATION_STARTED: `The registration for this session hasn't started yet.<br />Check back soon to enter or <a href="{{=CONTACT_US}}" target="website" style="text-decoration: underline">Contact Us</a> for more information.`,

        // Registration over
        EVENT_REGISTRATION_OVER: `Sorry, registration for this session has closed. <a href="{{=CONTACT_US}}" target="website" style="text-decoration: underline">Contact Us</a> for more information.`,

        // Entry limit
        EVENT_ENTRY_LIMIT: `Sorry, this session reached the entry limit. <a href="{{=CONTACT_US}}" target="website" style="text-decoration: underline">Contact Us</a> if you're interested in attending an upcoming session.`,

        // Event Over
        EVENT_DATE_OVER: `Sorry, this session has already taken place. <a href="{{=CONTACT_US}}" target="website" style="text-decoration: underline">Contact Us</a> if you're interested in attending an upcoming session.`,

        // Event not found
        EVENT_NOT_FOUND: `Sorry, the session could not be found. <a href="{{=CONTACT_US}}" target="website" style="text-decoration: underline">Contact Us</a> if you're interested in attending an upcoming session.`,

        // Event email
        EVENT_EMAIL: `Your event information email has been resent.`,
      },

      // Texts on the Test enter button. These buttons appear on Module and
      // Chapter subapplications.
      TEST_BTN: "Take the Test",
      TEST_BTN_INPROGRESS: "Continue the Test",
      TEST_BTN_PRACTICE: "Practice the Test",
    },

    // Program or Dashboard subapplication settings.
    PROGRAM: {
      // Message shown when the user is graduated.
      CONGRATS: "Congratulations, course completed.",

      // The time scale of how long will it take for the user to read all
      // the remaining chapters.
      MINUTES_READING_MIN: "Minutes<br>Reading",
      MINUTES_READING_HOUR: "Hours<br>Reading",

      // Initial quantity of items to show in mobile. On wider displays,
      // there is no load more.
      LOAD_MOBILE_INITIAL: 4,
      LOAD_MOBILE_ADD: 8,

      // Section/Module Test item button text of Test in progress when available.
      TEST_INPROGRESS: "Continue Pending Test",

      // The message explaining the Drink Builder.
      EXAM_MSG: `Make 7 classic cocktails (selected from your previous reading)`,
      EXAM_MSG_PRACTICE: `<b>Successfully completed!</b><br />Now practice your skills without affecting your previous results.`,

      // The status of the Drink Builder.
      EXAM_STATUS:
        "Complete all Module Tests to Unlock the DrinkBuilder Final Exam",
      EXAM_STATUS_INPROGRESS: `Your DrinkBuilder Final Exam is currently active. <a href="{{=link}}">Click here to continue</a>.`,

      // Texts on the Drink Builder exam enter button.
      EXAM_BTN: "Enter",
      EXAM_BTN_INPROGRESS: "Continue Active Exam",
      EXAM_BTN_PRACTICE: "Practice",
    },

    // Section or Module subapplication settings.
    SECTION: {
      // Initial quantity of items to show in mobile and medium and wider.
      LOAD_MOBILE_INITIAL: 3,
      LOAD_MEDIUM_INITIAL: 6,

      // How many items to add when loading more in mobile and medium and wider.
      LOAD_MOBILE_ADD: 6,
      LOAD_MEDIUM_ADD: 8,
    },

    // Chapter subapplication settings.
    CHAPTER: {
      // The duration of every clock interval.
      CLOCK_TIME: 1000,

      // Every time the clock runs, check out if the duration is this one.
      // If it is, track this time and sync it with the server as user Chapter
      // reading time. This should be greater or equal than `CLOCK_TIME` and
      // must be multiple of that value, example:
      // CLOCK_TIME = 1000
      // TRACK_INTERVAL_TIME = 60000
      // 1000 * 60 = 60000, it is multiple by 60.
      TRACK_INTERVAL_TIME: 60000,

      // What percentaje of reading time is considered as the user has read
      // a chapter. For example: if the value is 0.5 and the reading time
      // for a chapter is 10 minutes, then 10min * 0.5 = 5min. When the
      // user reaches 5 minutes as reading time, then the user has read the
      // the chapter.
      USER_READ_PERCENTAGE_TIME: 0.5,
    },

    // Test and Drink Builder subapplications shared settings.
    EVAL: {
      // The attempt button text.
      ATTEMPT_BTN: `{{=ordinal}} Attempt`,
      ATTEMPT_BTN_INPROGRESS: `Continue {{=ordinal}} Attempt`,

      // The modal message information when evaluation time has expired.
      TIME_EXPIRED: {
        TITLE: "Time Expired",
        DESCRIPTION: `Your time is up to complete this {{=type}} attempt. Please try again.`,
        BUTTON: "Close",
      },

      // The modal message information when user wants to quit the evaluation
      // while it is in course.
      QUIT: {
        TITLE: "Warning",
        DESCRIPTION: `You have {{=attempt}} total attempts to pass this {{=type}}. If you close this one, you will automatically fail the attempt. Please confirm.`,
        OK: "Exit {{=type}}",
        CANCEL: "Cancel",
      },
    },

    // Section/Module Test subapplication settings.
    TEST: {
      // The modal information with tips about the test.
      TIPS: {
        TITLE: "Module Test Tips",
        DESCRIPTION: `Each test question is multiple choice.<br/>
                You will have {{=minutes}} minutes to complete the test.<br/>
                You must answer {{=to_pass}} out of {{=per_test}} questions correctly to pass the test.<br/>
                <i style="color:red;font-size:140%;" class="icon icon-warning alert"></i><br/>
                <small>
                    Once a question is answered, you are not able to change it.<br/>
                    Exiting before completing the test will result in the displayed question being marked incorrect.<br/>
                    Hitting your browser's "Back" or "Reload" button will exit the test.<br/>
                    If you exit the test, you may re-enter and continue with the next question.<br/>
                    Time will continue to count down from when you began the test.
                </small>`,
        BUTTON: "Start Test",
      },

      // The reminders to the user in some test states.
      REMINDER: `Remember you have two total chances to pass each test.`,
      REMINDER_NOATTEMPTS: `No remaining attempts left. <a href="{{=request_link}}">Click here to request a reset</a>.`,
      REMINDER_RESETING: `Test reset request sent. You will be able to retake this test 24 hours after the reset request was sent. An email will be sent to you when this test has been re-opened.`,
      REMINDER_NORESETS: `No resets available. Please contact <a href="mailto:help@barsmarts.com">help@barsmarts.com</a> to request additional chances.`,
      REMINDER_PRACTICE: `You already passed this test. Click below to practice.`,

      // The information to solve the test.
      INFO: `Each question is multiple choice. In order to pass, you must answer {{=to_pass}} out of {{=per_test}} questions correctly. You will have {{=minutes}} minutes to complete the test.`,

      // The message when the user fails the test.
      RESULT_ERROR: {
        TITLE: `Nice try, but you didn't pass`,
        DESCRIPTION: `You need to pass {{=to_pass}} questions correctly. Study up, and try again.`,
      },

      // The message when the user completes successfully the test.
      RESULT_SUCCESS: {
        TITLE: "Congratulations, you’ve passed!",
        DESCRIPTION: `You're one step closer to being a {{=program}} Graduate.`,
      },

      // The message when the user fails the test in practice mode.
      RESULT_PRACTICE_ERROR: {
        TITLE: `Keep practicing!`,
        DESCRIPTION: `Only your original score is recorded. Feel free to practice the test as many times as you'd like without affecting your results.`,
      },

      // The message when the user completes successfully the test in practice mode.
      RESULT_PRACTICE_SUCCESS: {
        TITLE: `Congratulations, you've passed again!`,
        DESCRIPTION: `Only your original score is recorded. Feel free to practice the test as many times as you'd like without affecting your results.`,
      },
    },

    // Exam or Drink Builder subapplication settings.
    EXAM: {
      // The number of cocktails to ask the user to complete in each exam.
      PER_TEST: 7,

      // The modal information with tips about the exam.
      TIPS: {
        TITLE: "DrinkBuilder Tips",
        DESCRIPTION: `Each question is multiple choice.<br/>
                You will have {{=minutes}} minutes to complete the test.<br/>
                You must properly make {{=to_pass}} out of {{=per_test}} classic cocktails correctly to pass.<br/>
                You must answer all steps of a drink correctly in order to pass it.<br/>
                <i style="color:red;font-size:140%;" class="icon icon-warning alert"></i><br/>
                <small>
                    Once a question is answered, you are not able to change it.<br/>
                    Exiting before completing the test will result in the displayed question being marked incorrect.<br/>
                    Hitting your browser's “Back" or “Reload" button, you will exit the test.<br/>
                    If you exit the test you may re-enter and continue with the next question.<br/>
                    Time will continue to count down from when you began the test.
                </small>`,
        BUTTON: "Start Exam",
      },

      // The reminders to the user in some exam states.
      REMINDER: `Remember you have two total chances to pass the exam.`,
      REMINDER_NOATTEMPTS: `No remaining attempts left. <a href="{{=request_link}}">Click here to request a reset</a>.`,
      REMINDER_RESETING: `Exam reset request sent. You will be able to retake the exam 24 hours after the reset request was sent. An email will be sent to you when this exam has been re-opened.`,
      REMINDER_NORESETS: `No resets available. Please contact <a href="mailto:help@barsmarts.com">help@barsmarts.com</a> to request additional chances.`,
      REMINDER_PRACTICE: `You already passed the exam. Click below to practice.`,

      // The information to solve the BarStarts exam.
      BARSTARTS_INFO: `Congratulations on successfully completing Modules 1, 2, 3 and 4.
            You will now be required to pass the DrinkBuilder Final Exam.<br /><br />
            The DrinkBuilder will ask you to properly make 7 classic cocktails by choosing the correct
            ingredients, quantities, preparation, glass, and garnish.<br /><br />
            Cocktail recipes are taken from the Service Management Module section, titled either
            Classic 25 Drinks Every Bartender Should Know (BarSmarts) or Cocktail Recipes (BarStarts).<br /><br />
            Good Luck!`,

      // The information to solve the BarSmarts exam.
      BARSMARTS_INFO: `Congratulations on successfully completing Modules 1, 2, 3 and 4.
            You will now be required to pass the DrinkBuilder Final Exam.<br /><br />
            The DrinkBuilder will ask you to properly make 7 classic cocktails by choosing the correct
            ingredients, quantities, preparation, glass, and garnish.<br /><br />
            Cocktail recipes are taken from the Service Management Module section, titled either
            Classic 25 Drinks Every Bartender Should Know (BarSmarts) or Cocktail Recipes (BarStarts).<br /><br />
            Good Luck!`,

      // The message when the user fails the exam.
      RESULT_ERROR: {
        TITLE: `Nice try, but you didn't pass`,
        DESCRIPTION: `You need to build {{=to_pass}} cocktails correctly. Study up, and try again.`,
      },

      // The message when the user completes successfully the exam.
      RESULT_SUCCESS: {
        TITLE: "Congratulations, you’ve passed!",
        DESCRIPTION: `You're now a {{=program}} Graduate.`,
      },

      // The message when the user fails the exam in practice mode.
      RESULT_PRACTICE_ERROR: {
        TITLE: `Keep practicing!`,
        DESCRIPTION: `Only your original score is recorded. Feel free to practice the exam as many times as you'd like without affecting your results.`,
      },

      // The message when the user completes successfully the exam in practice mode.
      RESULT_PRACTICE_SUCCESS: {
        TITLE: `Congratulations, you've passed again!`,
        DESCRIPTION: `Only your original score is recorded. Feel free to practice the exam as many times as you'd like without affecting your results.`,
      },
    },
  },

  // CMS configuration constants.
  CMS: {
    // General application configurations.
    APP: {
      // General messages.
      MESSAGES: {
        // When the user tries to enter an unauthorized site or try to
        // execute a forbidden action.
        FORBIDDEN: {
          TITLE: "Forbidden",
          DESCRIPTION: "You are not authorized to do this action.",
          BUTTON: "Continue",
        },
      },
    },

    NOTIFICATIONS: {
      // Notifications menu status.
      STATUS: {
        // How often we update notifications from the server.
        UPDATE_INTERVAL: 30000,
      },

      // Notifications page.
      PAGE: {
        // We can try to fetch new notifications normally, but if we try
        // many concurrent requests, we only let one request in the next
        // time interval.
        FETCH_INTERVAL: 1000,

        // We can only re-render the notifications list once per the next
        // time interval.
        RENDER_INTERVAL: 1000,

        // How long we wait when we show the user the notifications to update
        // them in the server as read.
        DELAY_READ: 250,

        // How many items we load every time we hit the button "Load more"
        // in the server. This is only if we have enabled the filters
        // to load more notifications.
        LOAD_MORE: 100,

        // How many items we show each time we hit the "Load more" button
        // with the notifications filters.
        ADD_ITEMS: 100,
      },
    },

    // Configurations in the pages of the menu "Programs".
    PROGRAMS: {
      // General messages.
      MESSAGES: {
        // Error HTTP GET.
        ERROR_GET: {
          TITLE: "Error",
          DESCRIPTION: "There was an error while getting the information.",
          BUTTON: "Return to Programs", // Back to programs page.
        },

        // Error HTTP POST/PUT/PATCH/DELETE.
        ERROR_UPDATE: {
          TITLE: "Error",
          DESCRIPTION: "There was an error while updating the information.",
          BUTTON: "Refresh Page", // Will refresh the page.
        },
      },

      // Creating/editing sections.
      SECTIONS: {
        MESSAGES: {
          DELETE: {
            TITLE: "Delete Module",
            DESCRIPTION:
              "Are you sure you want to delete this module? All chapters will be deleted as well. Please note, this action is permanent.",
            ACCEPT: "Delete",
            CANCEL: "Cancel",
          },
        },

        // Default values to create a new module.
        DEFAULTS: {
          TITLE: "Untitled module",
        },

        // We make a server update request once per the next time interval.
        UPDATE_INTERNAL: 3000,

        // Section data fields.
        FIELDS: {
          // Download file.
          DOWNLOAD: {
            // Maximum size of the download file.
            MAX_SIZE: 50000000, // 50 MB
          },
        },
      },

      // Creating/editing chapters.
      CHAPTERS: {
        MESSAGES: {
          // Message to prevent the user from leaving the page when s/he has
          // unsaved changes.
          UNSAVED_CHANGES:
            "This chapter has unsaved changes. Do you want to leave the page and discard your changes?",

          // Delete chapter.
          DELETE: {
            TITLE: "Delete Chapter",
            DESCRIPTION:
              "Are you sure you want to delete this chapter? Please note, this action is permanent.",
            ACCEPT: "Delete",
            CANCEL: "Cancel",
          },

          // A warning message showd once per session indicating that any fragment edition/removal
          // will cause all its related notes and highlights being deleted.
          FRAGMENT_EFFECTS_WARNING: {
            TITLE: "Warning",
            DESCRIPTION:
              "When a chapter paragraph, subtitle or quote is edited or deleted, all Education Center notes and highlights related to that fragment of content created by users will be deleted.",
            BUTTON: "OK",
          },
        },

        // Default values to create a new chapter.
        DEFAULTS: {
          TITLE: "Untitled chapter",
          DESCRIPTION: "Undescribed chapter...",
          // READ_TIME: 5 * 60 * 1000, // 5 min
          READ_TIME: 5, // 5 min
        },

        // Only 1 update of chapter data is made to the server in this
        // duration interval.
        UPDATE_INTERNAL: 3000,

        // To calculate the chapter reading time, we count the words and
        // according to the next factor, we get the reading time by
        // words per minute.
        READ_WORDS_PER_MINUTE: 200,
      },
    },

    FRAGMENTS: {
      DELETE: {
        TITLE: "Delete this slide",
        DESCRIPTION:
          "Are you sure you want to delete this slide? Please note, this action is permanent.",
        ACCEPT: "Delete",
        CANCEL: "Cancel",
      },
    },

    // Google Analytics.
    GA: {
      CODE: false,
    },
  },
};

module.exports = config;
